<template>
  <div class="container full-height">
    <div class="row success-row">
      <div class="col-12">
        <div class="card-static fit-content text-center">
          <span v-html="text"></span>
          <button
            class="btn-filled"
            @click="$router.push({ name: button.route })"
          >
            {{ button.text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  data() {
    return {
      text: '',
      button: {
        text: '',
        route: '',
      },
    };
  },
  created() {
    const type = this.$route.params.type;
    this.getText(type);
  },
  methods: {
    getText(type) {
      if (type === 'email-verification') {
        this.text = `<p>This link seems to be expired. Please contact us.</p>`;
        this.button = {
          text: "Back to home",
          route: 'Home',
        };
      }

      if (type === '404') {
        this.text = `<p>The page your are looking for does not exist.</p>`;
        this.button = {
          text: 'Back to home',
          route: 'Home',
        };
      }
    },
  },
};
</script>

<style lang="scss">
.success-row {
  margin-top: $sp-3x;
}
</style>
